import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../templates/LayoutService'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../styles/components/boxes.module.scss'
import { bannerBlock, bannerBlockHeadline, bannerBlockTagline, bannerBlockTitleSm, pageContentBlock, pageContentMedia, pageCalloutBlock } from '../styles/components/blocks.module.scss'
import '../styles/components/content.module.scss'
import { Link } from 'gatsby'

const AreasPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>Private Party Boat Charter for Residents &amp; Visitors in Tampa Bay, Davis Islands, Hyde Park, SoHo, &amp; Surrounding Areas | Tampa Bay Boating Adventures</title>
        <meta name="description" content="We offer private party boat charters and sunset party boat charters in Tampa Bay, Davis Islands, Hyde Park, SoHo, and nearby areas in Tampa Bay, FL. Book yours today." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Group of friends talking on a party boat in Tampa Bay, FL."
                  src="../images/banners/group-of-friends-talking-on-party-boat-in-tampa-bay-florida.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>Party Boat Service Areas in Tampa Bay</h1>
                <span className={bannerBlockTitleSm}>Where to Find Our Services</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={pageContentBlock}>
              <h2>Private Party Boat Charter Available to Residents &amp; Visitors in Tampa Bay, Davis Islands, SoHo, Hyde Park, &amp; Nearby Areas in Florida</h2>
              <h3>We offer 8-hour, 6-hour, 4-hour, and sunset private party boat charters!</h3>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <StaticImage
                    alt="Friends on a party boat in Tampa Bay, Florida."
                    src="../images/banners/friends-on-party-boat-in-tampa-bay.jpg"
                  />
                </div>
              </div>
              <p>Are you looking for a unique and fun way to spend the day in the Tampa Bay area with your friends? If so, it’s time to sign up for a private party boat charter with Tampa Bay Boating Adventures. We cater to college-aged people who are looking to drink, soak up the sun, and party! If you attend the University of Tampa, just moved down here from the north, or live within the greater Tampa Bay area, and want to party out on the water, it’s time to sign up for one of our charters. We offer an 8-hour charter, 6-hour charter, 4-hour charter, and a sunset cruise! All of these private party charters are available to residents and visitors in Tampa Bay, Davis Islands, SoHo, Hyde Park, and throughout the surrounding areas.</p>
              <hr />
              <h2>Tampa Bay</h2>
              <p>There are a ton of ways to spend your time in Tampa, including visiting Busch Gardens, attending a Tampa Bay Lightning game, or hitting the bars on South Howard. But, as fun as all of that stuff can be, it doesn’t offer the excitement and unique experience that one of our private party boat charters does. If you live in Tampa Bay, take a break from the ordinary, and do something extraordinary by signing up for one of our charters!</p>
              <hr />
              <h2>Davis Islands</h2>
              <p>If you live on Davis Islands, you know just how beautiful this area is. In addition to the many great restaurants, you can get great views of the water by taking a stroll along the Davis Islands Trail. But why just take in the views from the shore? Instead, sign up for one of our private party boat charters and party the day away with your friends out on the water. Our trips can take you to local destinations such as Beer Can Island, private beaches, sand bars, and more!</p>
              <hr />
              <h2>Downtown Tampa</h2>
              <p>Downtown Tampa is an area brimming with life. With attractions like the Tampa Riverwalk, the Tampa Museum of Art, and the world-famous Eddie & Sam's N.Y. Pizzeria, it’s no wonder this area is so popular. But if you’re looking to steal away from the hustle and bustle of the city for a day and get out onto the open waters, our private party boat charters are just what you need. Whether you want to visit popular party islands, crank up some music while exploring local sandbars, or take shots on a float, we can make it happen!</p>
              <hr />
              <h2>University of Tampa</h2>
              <p>Do you attend the University of Tampa? If so, you know firsthand how great this college is! Located right on the west bank of the Hillsborough River, this beautiful campus is filled with young, smart, good-looking people who love to party just as much as they love to learn! But why not take a break from your studies, grab some friends, and have the time of your life on one of our private party boat charters?! All of our party boats are BYOB, so you can turn it into your own personal booze cruise!</p>
              <hr />
              <h2>Channelside District</h2>
              <p>The Channelside District is a growing and vibrant community that attracts lots of young adults who have the “work hard, party harder” mentality. This area has many popular attractions, including Sparkman Wharf, a fun and vibrant place where you can go to grab drinks, get a bite to eat, and appreciate the beauty of Tampa. If you call this place home, or are coming here for a visit, our private party boat charters are available to you!</p>
              <hr />
              <h2>Hyde Park</h2>
              <p>Hyde Park is a historic neighborhood that features great places to eat and a popular shopping district. If you live in or around this area, you are in close proximity to Tampa Bay Boating Adventures. Get away from the hustle and bustle of the neighborhood for a day and get out onto the water! We would love to have the privilege of taking you and your friends out to drink and party on one of our private party boat charters!</p>
              <div className={pageCalloutBlock}>
                <p>We’ll provide snacks, water, and towels, and you can bring any food, alcohol, or any other drinks you want!</p>
              </div>
              <h2>Ybor</h2>
              <p>This historic city is known for its cigars, Cuban sandwiches, and a vibrant nightlife. If you’re looking to drink and party, you’ll want to hit 7th Avenue which features many popular bars and clubs including The Castle, Zydeco Brew Works of Ybor City, Club Prana, and The Castle—just to name a few. While those bars and clubs can be fun, after one or two visits, it starts to feel like the same old thing. But if you’re looking for a unique and unforgettable experience, sign up for one of our private party boat charters. We can take you to a variety of local destinations including Beer Can Island, private islands, sandbars and much more. These trips are totally customizable, so we’ll go wherever your adventure takes you!</p>
              <hr />
              <h2>South Tampa</h2>
              <p>South Tampa is home to many beautiful neighborhoods including Beach Park, Palma Ceia, Ballast Point, Bayshore Gardens, Port Tampa, and many more! If you live in one of these neighborhoods, or are just visiting the area, it's time to sign up for one of our private party boat charters. We offer 8-hour, 6-hour, and 4-hour charters, as well as a sunset cruise. All of our charters are catered toward the young party crowd, and you can expect all the fun, sun, and music you can handle!</p>
              <hr />
              <h2>Harbour Island</h2>
              <p>Harbour Island is an exclusive neighborhood that’s considered a sub-district of Downtown Tampa. This beautiful area is located right near the Tampa Convention Center and features plenty of waterfront restaurants. If you live on or near this gorgeous island, don’t limit yourself to only partying on land. At Tampa Bay Boating Adventures, our private party boat charters will allow you and your friends to party in various locations in the Tampa-area waters. Bring along your closest friends and any drinks you want and get ready to have the time of your life partying with like-minded college-aged people who just want to let loose and have fun!</p>
              <hr />
              <h2>Sign up for any of our private party boat charters today!</h2>
              <p>At Tampa Bay Boating Adventures, we have one goal—to show you and your friends the time of your lives. We offer 8-hour, 6-hour, and 4-hour private party boat charters as well as a sunset cruise. All of our trips are BYOB, and we encourage you to bring any alcohol you want so you can turn your trip into your own personal booze cruise. We can take you to locations such as private islands, exclusive sandbars, Beer Can Island, and more. We can even dock at places like Sparkman Wharf, Armature Works, and Tampa Riverwalk if you want to hit any bars for a while. All our trips are customizable, so we can take you to one of our destinations, or multiple destinations, depending on your preference. We want you to have a blast, so we give you total control over the itinerary.</p>
              <p>We offer our trips to residents and visitors of Tampa Bay, Davis Islands, Downtown Tampa, Channelside District, Hyde Park, Ybor, SoHo, South Tampa, Harbour Island, and surrounding areas. Our party boat charters are also perfect for University of Tampa students who are looking to take their party game to the next level! Just <Link to="/contact">fill out our submission form</Link> or <a href="mailto:ahoy@tampabayboatingadventures.com">send us an email</a> to sign up for any of our private party boat charters today!</p>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default AreasPage